import React from "react";
import { Route, Switch } from "react-router-dom";

import createAsyncLoader from "./components/LoadingComponent";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const Home = createAsyncLoader(() => import("./containers/Home"));
const Login = createAsyncLoader(() => import("./containers/Login"));
const Signup = createAsyncLoader(() => import("./containers/Signup"));
const ResetPassword = createAsyncLoader(() => import("./containers/ResetPassword"));
const ItemList = createAsyncLoader(() => import("./containers/ItemList"));
const Item = createAsyncLoader(() => import("./containers/Item"));
const Settings = createAsyncLoader(() => import("./containers/Settings"));
const ChangePassword = createAsyncLoader(()=>import("./containers/ChangePassword"));
const ChangeEmail = createAsyncLoader(()=>import("./containers/ChangeEmail"));
const NotFound = createAsyncLoader(() => import("./containers/NotFound"));

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/items">
        <ItemList/>
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/items/new">
        <Item/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/items/:id/edit">
        <Item/>
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/email">
        <ChangeEmail />
      </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}