const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "dev-friend-thrift-infra-s3-uploads4f6eb0fd-17zgj63agsozz",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://mrfry85jqc.execute-api.ap-southeast-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_jg79MLIUg",
    APP_CLIENT_ID: "76i02sda4cjrbfna2stq85ct6",
    IDENTITY_POOL_ID: "ap-southeast-2:6c710bff-deb0-4510-9b67-3de631c7c460",
  },
};

export default config;
